import axios from "src/utils/axios";
import { EEndpointType } from "amp";
import { AxiosResponse } from "axios";
import {
	IPrepaidWashPromotion,
	IPrepaidWashPromotionConfig,
	XDynamicSqlName,
	ICreatePromotion,
	IUpdatePromotion,
	IStoredWashBalance,
	IStoredWashHistory,
} from "amp";
import { IDynamicQueryWithRefresh } from "src/components/dynamic-table/DynamicTablePage";
import { ENavCategory } from "src/enums/nav-category.enum";
import { generateManagmentRoutes } from "src/utils/generate-routes";

const DYNAMIC_ENDPOINT_TABLE_NAME: Extract<XDynamicSqlName, "prepaidWashPromotion"> =
	"prepaidWashPromotion";
const API_PREPAID_WASH_BASE_URL = `${EEndpointType.User}/prepaid-wash-promotions`;
const API_STORED_WASH_BASE_URL = `${EEndpointType.User}/stored-wash`;
const API_BASE_WEB_CONFIG_URL = `${EEndpointType.User}/prepaid-wash-config`;
const routes = generateManagmentRoutes(
	ENavCategory.marketing,
	"prepaid-wash-promotions",
);
const historyRoute = `${routes.BASE_ROUTE}/prepaid-wash-purchase-history`;

type IWebConfigEditForm = Omit<
	IPrepaidWashPromotionConfig,
	| "created"
	| "created_by_id"
	| "last_modified"
	| "last_modified_by_id"
	| "tenant__r__external_id__c"
	| "id"
> & {
	id?: number;
};

const BASE_QUERY: IDynamicQueryWithRefresh = {
	refresh: Number.MIN_SAFE_INTEGER,
	columns: [
		"id",
		"name",
		"sub_title",
		"tile_image_url",
		"effective_to",
		"effective_from",
		"ordinal",
	],
	orderBys: [{ column: "ordinal", direction: "ASC" }],
};

const create = async (
	createBody: ICreatePromotion,
): Promise<IPrepaidWashPromotion> => {
	const response = await axios.post<IPrepaidWashPromotion>(
		`${API_PREPAID_WASH_BASE_URL}/create`,
		createBody,
	);
	return response.data;
};

const update = (
	updateBody: IUpdatePromotion,
): Promise<AxiosResponse<IPrepaidWashPromotion>> => {
	return axios.post<IPrepaidWashPromotion>(
		`${API_PREPAID_WASH_BASE_URL}/update`,
		updateBody,
	);
};

const createWebConfig = async (
	createBody: IWebConfigEditForm,
): Promise<IPrepaidWashPromotionConfig> => {
	const response = await axios.post<IPrepaidWashPromotionConfig>(
		`${API_BASE_WEB_CONFIG_URL}/create`,
		createBody,
	);
	return response.data;
};

const updateWebConfig = (
	updateBody: IWebConfigEditForm,
): Promise<AxiosResponse<IPrepaidWashPromotionConfig>> => {
	return axios.post<IPrepaidWashPromotionConfig>(
		`${API_BASE_WEB_CONFIG_URL}/update`,
		updateBody,
	);
};

const getWashBalances = async (userId: string): Promise<IStoredWashBalance[]> => {
	const response = await axios.post<IStoredWashBalance[]>(
		`${API_STORED_WASH_BASE_URL}/get-wash-balances`,
		{ userId }
	);
	return response.data;
};

const getHistory = async (userId: string): Promise<IStoredWashHistory[]> => {
	const response = await axios.post<IStoredWashHistory[]>(
		`${API_STORED_WASH_BASE_URL}/get-history`,
		{ userId }
	);
	return response.data; 
};

export const PrepaidWashPromotionService = {
	DYNAMIC_ENDPOINT_TABLE_NAME,
	API_BASE_URL: API_PREPAID_WASH_BASE_URL,
	BASE_QUERY,
	create,
	update,
	getWashBalances,
	getHistory,
	createWebConfig,
	updateWebConfig,
	historyRoute,
	...routes,
};
